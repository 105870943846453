import React from "react"

import Layout from '../components/layout'

import { TitlePage } from '../components/common/titlePage'
import { Gallery } from '../components/gallery'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'



const LodzGaleria = () => {

  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "dermapen-4-efekty-zabiegow.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            
          )
        }
      }
    }
`)

return (
  <Layout lodz>
    <Seo
      title="Lodz galeria"
      description="Galeria przed i po zabiegach w BodyMed Group Lodz."
      url="https://bodymedgroup.pl/lodz-blog"
    />
    <TitlePage
      title='Efekty zabiegów' 
      text='Wahasz się czy zabieg, który Cię interesuje przynosi efekty? Przejrzyj naszą galerię "przed i po" i przekonaj się sama!' 
      imageTitle={data.file.childImageSharp.gatsbyImageData}
      titleSeoImage="BodyMed Group" 
      altSeoImage="BodyMed Group"
    />
    <Gallery />
  </Layout>
)}

export default LodzGaleria
